@import "../../styles/variable.scss";
.container {
  padding: 44px 48px 32px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 145px;
  height: 122px;
  margin-bottom: 42px;
}
