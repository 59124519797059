@import "../../styles/variable.scss";

.input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 16px;
  background: $onPrimaryColor;
  border: 1px solid $seperatorColor;
  box-sizing: border-box;
  border-radius: 8px;
}

.carretDownImg {
  width: 12px;
  height: 8px;
  margin-left: 8px;
}

.bottomSheetContent {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  // max-height: 600px;
  // overflow-y: scroll;
}

.accountItem {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $seperatorColor;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioImg {
  width: 24px;
  height: 24px;
}
.accountContainer {
  padding-bottom: 120px;
  max-height: 400px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.accountContainer::-webkit-scrollbar {
  display: none;
}

.valueContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
