@import "../../styles/variable.scss";
.chevronRight {
  width: 16px;
  height: 16px;
}

.bankImg {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.bottomSheetContent {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  // max-height: 600px;
  // overflow-y: scroll;
}

.accountItem {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $seperatorColor;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioImg {
  width: 24px;
  height: 24px;
}

.accountContainer {
  padding-bottom: 120px;
  max-height: 400px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.accountContainer::-webkit-scrollbar {
  display: none;
}
