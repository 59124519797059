@import "../../styles/variable.scss";

.emptyStateContainer {
  padding-top: 87px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bankEmptyImg {
  width: 229px;
  height: 184px;
}

.container {
  padding: 24px 16px;
  background-color: $background;
}
.menuName {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  flex: 1;
}
.chevronRight {
  width: 16px;
  height: 16px;
}

.bankImg {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.bankItem {
  padding: 16px;
  background-color: $onPrimaryColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $seperatorColor;
}
.bankItemLast {
  padding: 16px;
  background-color: $onPrimaryColor;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bankItemImg {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.noResultImg {
  width: 213px;
  height: 178px;
  margin-top: 100px;
  margin-bottom: 32px;
}
