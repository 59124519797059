@import "../../styles/variable.scss";
.container {
  padding: 24px 16px;
}
.menuName {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  flex: 1;
}
.chevronRight {
  width: 16px;
  height: 16px;
}

.bankImg {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.bankItem {
  padding: 16px;
  background-color: $onPrimaryColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $seperatorColor;
}

.bankItemImg {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.moreInfoText {
  color: $blueLink;
  font-weight: 600;
}
.warningContainer {
  padding: 16px;
  border-radius: 8px;
  background-color: $lightBlue;
  display: flex;
  flex-direction: row;
}

.shieldImg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.infoBlueImg {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
.bottomSheetContent {
  padding: 24px 16px;
}
.cardGuide {
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}
.dot {
  min-width: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
