@import "./variable.scss";
// Custom header navbar
#header .navbar-nav .nav-link.active {
  font-family: "Averta Std CY";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 12px 0px 12px 0px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #fb4390;
  @media only screen and (min-width: 992px) {
    margin: 0px 25px;
  }
}

#header .navbar-nav .nav-link {
  font-family: "Averta Std CY";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 12px 0px 12px 0px;
  @media only screen and (min-width: 992px) {
    margin: 0px 25px;
  }
}

.form-label {
  font-family: "Averta Std CY";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: $gray;
  margin-bottom: 0.5rem;
}
.input-group input {
  height: $inputHeight;
}
.input-group select {
  height: $inputHeight;
}
// .input-group {
//   height: $inputHeight;
// }
.form-control {
  height: $inputHeight;
}

.btn-outline-light {
  color: $gray !important;
  border-color: $gray2 !important;
}
.btn-light {
  color: $gray !important;
  background-color: $gray2 !important;
}
.input-group-text {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-left: 1px solid $input-border-color !important;
  border-top: 1px solid $input-border-color !important;
  border-bottom: 1px solid $input-border-color !important;
}
.input-group-text.error {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-left: 1px solid $errorColor !important;
  border-top: 1px solid $errorColor !important;
  border-bottom: 1px solid $errorColor !important;
}
.datePicker.form-control.is-invalid {
  background-image: none !important;
}
.noErrorIcon.form-control.is-invalid {
  background-image: none !important;
}
@import "~bootstrap/scss/bootstrap";
