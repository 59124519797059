@import "../../styles/variable.scss";
.container {
  padding: 24px 16px;
  background-color: $background;
}
.infoContainer {
  background-color: $onPrimaryColor;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
}
.infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  padding: 20px 0px;
  border-bottom: 1px dashed $seperatorColor;
}

.infoRowWithoutBorder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  padding: 20px 0px;
}
