@import "../../styles/variable.scss";
.container {
  padding: 24px 16px;
  // background-color: $background;
}

.arrowLeft {
  width: 48px;
  height: 48px;
  margin-right: 48px;
}
.arrowRight {
  width: 48px;
  height: 48px;
}
.actionIcon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.cardContainer {
  background-image: url("../../assets/images/card.png");
  width: 320px;
  height: 200px;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  position: relative;
  padding: 20px;
  margin-bottom: 8px;
}

.napasImg {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 78px;
  height: 20px;
}
.cardTextBold {
  font-size: 12px;
  line-height: 13.69px;
  font-family: Kuro;
  font-weight: bold;
  color: $onPrimaryColor;
  letter-spacing: -0.3px;
}
.cardNumber {
  font-size: 20px;
  line-height: 22.82px;
  font-family: Kuro;
  font-weight: bold;
  color: $onPrimaryColor;
}
.memberSinceText {
  font-size: 8px;
  line-height: 9.13px;
  font-family: Kuro;
  font-weight: bold;
  color: $onPrimaryColor;
  opacity: 0.5;
  margin-right: 8px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}
.actionContainer {
  padding: 0px 20px;
}
