@import "../../styles/variable.scss";

.bottomSheetOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}
.bottomSheetContainer {
  background-color: $onPrimaryColor;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -16px 60px rgba(0, 0, 0, 0.15);
  pointer-events: all;
  padding-bottom: calc(max(0px, env(safe-area-inset-bottom)));
  z-index: 5000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.header {
  padding: 16px;
  background-color: $primaryColor;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}
.headerTitleContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.closeImg {
  width: 20px;
  height: 20px;
}
