@import "../../styles/variable.scss";
.addNewCardItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid $seperatorColor;
  border-radius: 8px;
}

.plusImageContainer {
  border: 1px dashed $primaryColor;
  box-sizing: border-box;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.bankItemSelected {
  border: 2px solid $primaryColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: $primary10;
  margin-bottom: 12px;
}

.bankItem {
  border: 1px solid $seperatorColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 12px;
}

.plusImg {
  width: 24px;
  height: 24px;
}
.chevronRight {
  width: 16px;
  height: 16px;
}
.bankImg {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.radioImg {
  width: 24px;
  height: 24px;
}
