@import "../../styles/variable.scss";
.container {
  padding: 44px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 145px;
  height: 122px;
  margin-bottom: 38px;
}

.infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  padding: 20px 0px;
  border-bottom: 1px dashed $seperatorColor;
}

.infoRowWithoutBorder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  padding: 20px 0px;
}

.infoBlock {
  margin-bottom: 44px;
  width: 100%;
}
.erorrContainer {
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 8px;
  background-color: $error20Color;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.errorImg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
