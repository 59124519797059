@import "../../styles/variable.scss";
.container {
  padding: 24px 16px;
  background-color: $background;
}

.plusImg {
  margin-left: 4px;
  margin-right: 20px;
  width: 24px;
  height: 24px;
}
.menuName {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  flex: 1;
}
.chevronRight {
  width: 16px;
  height: 16px;
}

.bankImg {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
