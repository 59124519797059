@import "../../styles/variable.scss";
.searchImg {
  width: 20px;
  height: 20px;
}
.searchInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  background: $onPrimaryColor;
  box-shadow: 0px 2px 4px rgba(35, 87, 234, 0.06),
    0px 3px 4px rgba(35, 87, 234, 0.06), 0px 1px 5px rgba(35, 87, 234, 0.06);
  border-radius: 8px;
}
.input {
  flex: 1;
  border: none;
}
.input:focus {
  outline: none;
}

.input::placeholder {
  color: $placeholderColor;
}

.input:-ms-input-placeholder {
  color: $placeholderColor;
}
