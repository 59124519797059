@import "../../styles/variable.scss";
.container {
  padding: 24px 16px;
  background-color: $background;
}

.plusImg {
  margin-left: 4px;
  margin-right: 20px;
  width: 24px;
  height: 24px;
}
.menuName {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  flex: 1;
}

.mainContent {
  padding: 20px 16px;
  background-color: $onPrimaryColor;
}

.moneyInput {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: $primaryColor !important;
}
.predefineMoneyItem {
  background-color: $background;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
