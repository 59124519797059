@import "../../styles/variable.scss";
.emptyStateContainer {
  padding: 56px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 300px;
  height: 206px;
  margin-bottom: 48px;
}

.menuContainer {
  padding: 24px 16px;
  background-color: $background;
}
.menuItem {
  padding: 26px 20px;
  background-color: $onPrimaryColor;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(35, 87, 234, 0.06),
    0px 3px 4px rgba(35, 87, 234, 0.06), 0px 1px 5px rgba(35, 87, 234, 0.06);
}
.menuImg {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}
.menuName {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  flex: 1;
}
.chevronRight {
  width: 16px;
  height: 16px;
}
