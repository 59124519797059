$primaryColor: #141ccc;
$primary10: rgba(20, 28, 204, 0.1);
$lightBlue: rgba(147, 209, 242, 0.2);
$lightBlue2: #e6e7f9;
$onPrimaryColor: #ffffff;
$primaryDarkColor: #00377a;
$onPrimaryDarkColor: #b8c7da;
$secondaryColor: #dd0071;
$secondaryLightColor: #fae6e4;
$textBlack: #111111;
$textBlack2: #112843;
$textBlack2Opacity: rgba(17, 40, 67, 0.5);
$whiteColor: #ffffff;
$gray: #858585;
$grayBackground: #f4f4f4;
$background: #f4f4f4;
$gray2: #eeeeee;
$gray3: #999999;
$gray4: rgba(168, 168, 168, 0.5);
$placeholderColor: #a8a8a8;
$seperatorColor: #e0e0e0;
$errorColor: #db3832;
$error20Color: #db383233;
$blueLink: #1697f4;
$green: #5cb157;

$theme-colors: (
  "primary": $primaryColor,
);

$inputHeight: 48px;

// Bootstrap
$navbar-light-active-color: $secondaryColor;
$navbar-light-color: $primaryColor;
$navbar-light-hover-color: $secondaryColor;
$navbar-nav-link-padding-x: 0px;
$navbar-nav-link-padding-x: 0px;
$font-family-base: "Averta Std CY", sans-serif;
$btn-font-family: "Averta Std CY";
$btn-font-weight: 600;
$btn-line-height: 24px;
$btn-font-size: 14px;
$btn-padding-y: 12px;
$btn-padding-x: 48px;
$btn-border-radius: 8px;

$input-border-color: $gray2;
$input-placeholder-color: $placeholderColor;
$accordion-button-active-color: $textBlack;
$accordion-button-bg: $whiteColor;
$accordion-button-active-bg: $whiteColor;
$form-feedback-invalid-color: $errorColor;
$accordion-border-color: $gray2;
