@import "../../styles/variable.scss";
.toolbarContainer {
  // padding-top: max(2rem, env(safe-area-inset-top));
  background-color: $primaryColor;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.toolbar {
  // flex flex-row items-center justify-between relative w-full titleContainerNormal
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
}
.titleContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.chevronLeftImg {
  width: 20px;
  height: 20px;
}
.backIconPlacehilder {
  width: 20px;
  height: 20px;
}
