body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.toolbarContainerTop {
  padding-top: max(32px, env(safe-area-inset-top));
}

.screenContainer {
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.screenScrollContainer {
  overflow-y: scroll;
  height: calc(100vh - max(32px, env(safe-area-inset-top)) - 40px);
  overscroll-behavior: none;
}
.bottomButtonBlock {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 16px 16px calc(max(0px, env(safe-area-inset-bottom)) + 16px) 16px;
  z-index: 20;
  background-color: white;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.12);
}

.bottomButtonBlock2 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 16px 16px calc(max(0px, env(safe-area-inset-bottom)) + 16px) 16px;
  z-index: 20;
}

.bottomButtonSpacer {
  height: calc(16px + 40px + max(0px, env(safe-area-inset-bottom)) + 16px);
}
.bottomBarSpacer {
  padding-bottom: calc(max(0px, env(safe-area-inset-bottom)));
}
